import { getCurrentImageUrl } from '../../utilities/general/locale'
import { getImageName } from '../../utilities/general/image'
import { graphql } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

export interface ImageData {
    filename: string
    height: number
    width: number
    dateUpdated: string
    hasFocalPoint?: boolean
    focalPoint?: [number, number]
}

export enum ImageVersion {
    Small,
    Medium,
    Large,
    FullWidth,
}

export interface ImgProperties {
    focalPoint?: false | [number, number]
}

export interface ImageProps {
    data: {
        image: ImageData[]
        alt?: string | null
    }
    version: ImageVersion
    shrinkImageIfNecessary?: boolean
}

const Image = ({ data, version, shrinkImageIfNecessary = false }: ImageProps): JSX.Element => {
    const { filename, height, width, dateUpdated, hasFocalPoint, focalPoint } = data.image[0]
    const alt = data?.alt ? data.alt : ''
    const imageUrl = getCurrentImageUrl()
    const name = getImageName(filename)

    return (
        <Picture style={shrinkImageIfNecessary ? { maxWidth: `${width}px` } : {}}>
            {version === ImageVersion.FullWidth && (
                <>
                    <source
                        type="image/webp"
                        srcSet={`${imageUrl}/${name}_intrinsic.webp?v=${dateUpdated}`}
                        media="(min-width: 1700px)"
                    />
                    <source
                        type="image/jpeg"
                        srcSet={`${imageUrl}/${name}_intrinsic.jpeg?v=${dateUpdated}`}
                        media="(min-width: 1700px)"
                    />
                    <source
                        type="image/webp"
                        srcSet={`${imageUrl}/${name}_xxl_1x.webp?v=${dateUpdated}, ${imageUrl}/${name}_xxl_1-5x.webp?v=${dateUpdated} 1.5x, ${imageUrl}/${name}_xxl_2x.webp?v=${dateUpdated} 2x`}
                        media="(min-width: 1200px)"
                    />
                    <source
                        type="image/jpeg"
                        srcSet={`${imageUrl}/${name}_xxl_1x.jpeg?v=${dateUpdated}, ${imageUrl}/${name}_xxl_1-5x.jpeg?v=${dateUpdated} 1.5x, ${imageUrl}/${name}_xxl_2x.jpeg?v=${dateUpdated} 2x`}
                        media="(min-width: 1200px)"
                    />
                </>
            )}
            {[ImageVersion.Large, ImageVersion.FullWidth].includes(version) && (
                <>
                    <source
                        type="image/webp"
                        srcSet={`${imageUrl}/${name}_xl_1x.webp?v=${dateUpdated}, ${imageUrl}/${name}_xl_1-5x.webp?v=${dateUpdated} 1.5x, ${imageUrl}/${name}_xl_2x.webp?v=${dateUpdated} 2x`}
                        media="(min-width: 800px)"
                    />
                    <source
                        type="image/jpeg"
                        srcSet={`${imageUrl}/${name}_xl_1x.jpeg?v=${dateUpdated}, ${imageUrl}/${name}_xl_1-5x.jpeg?v=${dateUpdated} 1.5x, ${imageUrl}/${name}_xl_2x.jpeg?v=${dateUpdated} 2x`}
                        media="(min-width: 800px)"
                    />
                </>
            )}
            {version !== ImageVersion.Small && (
                <>
                    <source
                        type="image/webp"
                        srcSet={`${imageUrl}/${name}_large_1x.webp?v=${dateUpdated}, ${imageUrl}/${name}_large_1-5x.webp?v=${dateUpdated} 1.5x, ${imageUrl}/${name}_large_2x.webp?v=${dateUpdated} 2x`}
                        media="(min-width: 580px)"
                    />
                    <source
                        type="image/jpeg"
                        srcSet={`${imageUrl}/${name}_large_1x.jpeg?v=${dateUpdated}, ${imageUrl}/${name}_large_1-5x.jpeg?v=${dateUpdated} 1.5x, ${imageUrl}/${name}_large_2x.jpeg?v=${dateUpdated} 2x`}
                        media="(min-width: 580px)"
                    />
                    <source
                        type="image/webp"
                        srcSet={`${imageUrl}/${name}_medium_1x.webp?v=${dateUpdated}, ${imageUrl}/${name}_medium_1-5x.webp?v=${dateUpdated} 1.5x, ${imageUrl}/${name}_medium_2x.webp?v=${dateUpdated} 2x`}
                        media="(min-width: 370px)"
                    />
                    <source
                        type="image/jpeg"
                        srcSet={`${imageUrl}/${name}_medium_1x.jpeg?v=${dateUpdated}, ${imageUrl}/${name}_medium_1-5x.jpeg?v=${dateUpdated} 1.5x, ${imageUrl}/${name}_medium_2x.jpeg?v=${dateUpdated} 2x`}
                        media="(min-width: 370px)"
                    />
                </>
            )}
            <source
                type="image/webp"
                srcSet={`${imageUrl}/${name}_small_1x.webp?v=${dateUpdated}, ${imageUrl}/${name}_small_1-5x.webp?v=${dateUpdated} 1.5x, ${imageUrl}/${name}_small_2x.webp?v=${dateUpdated} 2x`}
            />
            <source
                type="image/jpeg"
                srcSet={`${imageUrl}/${name}_small_1x.jpeg?v=${dateUpdated}, ${imageUrl}/${name}_small_1-5x.jpeg?v=${dateUpdated} 1.5x, ${imageUrl}/${name}_small_2x.jpeg?v=${dateUpdated} 2x`}
            />
            <Img
                src={`${imageUrl}/${name}_intrinsic.jpeg?v=${dateUpdated}`}
                alt={alt || ''}
                height={height}
                width={width}
                loading={version === ImageVersion.FullWidth ? 'eager' : 'lazy'}
                focalPoint={hasFocalPoint && focalPoint}
            />
        </Picture>
    )
}

export default Image

const Picture = styled.picture`
    height: auto;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
`

const Img = styled.img<ImgProperties>`
    height: auto;
    width: 100%;
    ${props => {
        const [positionX, positionY] = (props.focalPoint || [0.5, 0.5]).map(focalPoint => `${focalPoint * 100}%`)

        return css`
            object-position: ${positionX} ${positionY};
        `
    }}
`

export const query = graphql`
    fragment ImageFragment on Craft_components_image_BlockType {
        image {
            filename
            dateUpdated
            height
            width
        }
        alt
    }

    fragment ImageReusableComponentFragment on Craft_componentType_image_BlockType {
        image {
            filename
            dateUpdated
            height
            width
        }
        alt
    }
`
